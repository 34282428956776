import { ReactNode, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import {
  AdminDashboardRoutes,
  ClientDashboardRoutes,
  EnrollmentDashboardRoutes,
  ProjectDashboardRoutes,
  Routes,
} from './routes';

import ClientRoute from '@/components/accessWrappers/ClientRoute';
import EnrollmentProjectRoute from '@/components/accessWrappers/EnrollmentProjectRoute';
import EnrollmentRoute from '@/components/accessWrappers/EnrollmentRoute';
import FileEditRoute from '@/components/accessWrappers/FileEditRoute';
import ProjectEditRoute from '@/components/accessWrappers/ProjectEditRoute';
import ProjectRoute from '@/components/accessWrappers/ProjectRoute';

import Loading from '@/components/elements/Loading';
import PathHandler from '@/components/elements/PathHandler';
import MainLayout from '@/components/layout/MainLayout';
import {
  MobileMenuContext,
  useMobileMenuContext,
} from '@/components/layout/nav/useMobileMenuContext';

import NotFound from '@/components/pages/NotFound';
import AdminDashboard, {
  AdminLandingPage,
} from '@/modules/admin/components/AdminDashboard';

import FormDefinitionDetailPage from '@/modules/admin/components/forms/FormDefinitionDetailPage';
import FormDefinitionsPage from '@/modules/admin/components/forms/FormDefinitionsPage';
import FormPreview from '@/modules/admin/components/forms/FormPreview';
import JsonFormEditorPage from '@/modules/admin/components/forms/JsonFormEditorPage';
import ProjectConfigPage from '@/modules/admin/components/projectConfig/ProjectConfigPage';
import ServiceTypeDetailPage from '@/modules/admin/components/services/ServiceTypeDetailPage';
import ServiceTypePage from '@/modules/admin/components/services/ServiceTypePage';
import AdminUsers from '@/modules/admin/components/users/AdminUsers';
import UserAuditPage from '@/modules/admin/components/users/UserAuditPage';
import ExitAssessmentPage from '@/modules/assessments/components/ExitAssessmentPage';
import IndividualAssessmentPage from '@/modules/assessments/components/IndividualAssessmentPage';
import IntakeAssessmentPage from '@/modules/assessments/components/IntakeAssessmentPage';
import NewIndividualAssessmentPage from '@/modules/assessments/components/NewIndividualAssessmentPage';
import ClientAssessmentsPage from '@/modules/assessments/components/pages/ClientAssessmentsPage';
import ClientAuditHistory from '@/modules/audit/components/ClientAuditHistory';
import EnrollmentAuditHistory from '@/modules/audit/components/EnrollmentAuditHistory';
import BedNightsPage from '@/modules/bulkServices/components/BedNightsPage';
import BulkServicesPage from '@/modules/bulkServices/components/BulkServicesPage';

import ClientCaseNotes from '@/modules/caseNotes/components/ClientCaseNotes';
import EnrollmentCaseNotes from '@/modules/caseNotes/components/EnrollmentCaseNotes';

import ClientDashboard from '@/modules/client/components/pages/ClientDashboard';
import ClientProfilePage from '@/modules/client/components/pages/ClientProfilePage';
import CreateClientPage from '@/modules/client/components/pages/CreateClientPage';
import EditClientPage from '@/modules/client/components/pages/EditClientPage';
import ClientFilesPage from '@/modules/clientFiles/components/ClientFilesPage';
import EditFilePage from '@/modules/clientFiles/components/EditFilePage';
import AdminClientMerge from '@/modules/clientMerge/components/admin/AdminClientMerge';
import GlobalClientMergeHistory from '@/modules/clientMerge/components/admin/GlobalClientMergeHistory';
import ClientMergeHistory from '@/modules/clientMerge/components/client/ClientMergeHistory';
import NewClientMerge from '@/modules/clientMerge/components/client/NewClientMerge';
import ClientEnrollmentsPage from '@/modules/enrollment/components/pages/ClientEnrollmentsPage';
import EnrollmentAssessmentsPage from '@/modules/enrollment/components/pages/EnrollmentAssessmentsPage';
import EnrollmentCeAssessmentsPage from '@/modules/enrollment/components/pages/EnrollmentCeAssessmentsPage';
import EnrollmentCeEventsPage from '@/modules/enrollment/components/pages/EnrollmentCeEventsPage';
import EnrollmentCurrentLivingSituationsPage from '@/modules/enrollment/components/pages/EnrollmentCurrentLivingSituationsPage';
import EnrollmentDashboard from '@/modules/enrollment/components/pages/EnrollmentDashboard';
import EnrollmentEsgFundingReport from '@/modules/enrollment/components/pages/EnrollmentEsgFundingReport';
import EnrollmentOverview from '@/modules/enrollment/components/pages/EnrollmentOverview';

import HouseholdPage from '@/modules/enrollment/components/pages/HouseholdPage';
import SentryErrorBoundary from '@/modules/errors/components/SentryErrorBoundary';
import FormBuilderPage from '@/modules/formBuilder/components/FormBuilderPage';
import CreateHouseholdPage from '@/modules/household/components/CreateHouseholdPage';
import EditHouseholdPage from '@/modules/household/components/EditHouseholdPage';
import MyDashboardPage from '@/modules/myDashboard/components/MyDashboardPage';
import { RootPermissionsFilter } from '@/modules/permissions/PermissionsFilters';
import AllProjectsPage from '@/modules/projectAdministration/components/AllProjectsPage';
import CeParticipationsPage from '@/modules/projectAdministration/components/CeParticipationsPage';
import CreateOrganizationPage from '@/modules/projectAdministration/components/CreateOrganizationPage';
import CreateProjectPage from '@/modules/projectAdministration/components/CreateProjectPage';
import EditFunderPage from '@/modules/projectAdministration/components/EditFunderPage';
import EditInventoryPage from '@/modules/projectAdministration/components/EditInventoryPage';
import EditOrganizationPage from '@/modules/projectAdministration/components/EditOrganizationPage';
import EditProjectCocPage from '@/modules/projectAdministration/components/EditProjectCocPage';
import EditProjectPage from '@/modules/projectAdministration/components/EditProjectPage';
import FundersPage from '@/modules/projectAdministration/components/FundersPage';
import HmisParticipationsPage from '@/modules/projectAdministration/components/HmisParticipationsPage';
import InventoriesPage from '@/modules/projectAdministration/components/InventoriesPage';
import OrganizationPage from '@/modules/projectAdministration/components/OrganizationPage';
import ProjectCocsPage from '@/modules/projectAdministration/components/ProjectCocsPage';
import ProjectAssessments from '@/modules/projects/components/ProjectAssessments';
import ProjectCurrentLivingSituations from '@/modules/projects/components/ProjectCurrentLivingSituations';
import ProjectDashboard from '@/modules/projects/components/ProjectDashboard';
import ProjectEnrollments from '@/modules/projects/components/ProjectEnrollments';
import ProjectEsgFundingReport from '@/modules/projects/components/ProjectEsgFundingReport';
import ProjectExternalFormSubmissions from '@/modules/projects/components/ProjectExternalFormSubmissions';
import ProjectOverviewPage from '@/modules/projects/components/ProjectOverviewPage';
import AdminReferralDenialsPage from '@/modules/referrals/components/admin/AdminReferralDenialsPage';
import AdminReferralPosting from '@/modules/referrals/components/admin/AdminReferralPosting';
import NewOutgoingReferralPage from '@/modules/referrals/components/NewOutgoingReferralPage';
import NewReferralRequestPage from '@/modules/referrals/components/NewReferralRequestPage';
import ProjectReferralPostingPage from '@/modules/referrals/components/ProjectReferralPostingPage';

import ProjectReferralsPage from '@/modules/referrals/components/ProjectReferralsPage';
import ClientScanCards from '@/modules/scanCards/components/ClientScanCards';
import ClientSearchPage from '@/modules/search/components/ClientSearchPage';

import ClientServicesPage from '@/modules/services/components/ClientServicesPage';
import EnrollmentServicesPage from '@/modules/services/components/EnrollmentServicesPage';
import ProjectServicesPage from '@/modules/services/components/ProjectServicesPage';
import SystemStatus from '@/modules/systemStatus/components/SystemStatus';
import Units from '@/modules/units/components/Units';
import { DataCollectionFeatureRole } from '@/types/gqlTypes';

const App = () => {
  // Setup mobile menu context - open/closed state and handlers
  const mobileMenuContext = useMobileMenuContext();

  return (
    <MainLayout
      // Provide mobile menu context directly to the Main Layout for rendering the non-Dashboard nav.
      // Can't use `useMobileMenu` hook for the context provided by the Outlet, since this is outside the Outlet.
      mobileMenuContext={mobileMenuContext}
    >
      <Suspense fallback={<Loading />}>
        <SentryErrorBoundary>
          <Outlet
            // Provide mobile menu context to the children via Outlet so that Dashboard components
            // can use the same menu component but inject their own navigational elements
            context={mobileMenuContext satisfies MobileMenuContext}
          />
        </SentryErrorBoundary>
      </Suspense>
    </MainLayout>
  );
};

// const ParamsWrapper = <T extends { [x: string]: string } = any>({
//   children,
// }: {
//   children: (params: T) => JSX.Element;
// }): JSX.Element => {
//   const params = useSafeParams() as T;
//   return children(params);
// };

interface RouteNode {
  path: string;
  element: ReactNode;
  children?: RouteNode[];
}
export const protectedRoutes: RouteNode[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: Routes.ALL_PROJECTS, element: <AllProjectsPage /> },
      { path: Routes.MY_DASHBOARD, element: <MyDashboardPage /> },
      {
        path: Routes.PROJECT,
        element: <ProjectDashboard />,
        children: [
          {
            path: '',
            element: (
              <ProjectRoute
                permissions={['canViewEnrollmentDetails']}
                redirectRoute={ProjectDashboardRoutes.OVERVIEW}
              >
                <Navigate to='enrollments' replace />
              </ProjectRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.OVERVIEW,
            element: <ProjectOverviewPage />,
          },
          {
            path: ProjectDashboardRoutes.PROJECT_ENROLLMENTS,
            element: <ProjectEnrollments />,
          },
          {
            path: ProjectDashboardRoutes.PROJECT_ASSESSMENTS,
            element: <ProjectAssessments />,
          },
          {
            path: ProjectDashboardRoutes.PROJECT_SERVICES,
            element: (
              <ProjectRoute
                dataCollectionFeature={DataCollectionFeatureRole.Service}
              >
                <ProjectServicesPage />
              </ProjectRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.PROJECT_CURRENT_LIVING_SITUATIONS,
            element: (
              <ProjectRoute
                dataCollectionFeature={
                  DataCollectionFeatureRole.CurrentLivingSituation
                }
              >
                <ProjectCurrentLivingSituations />
              </ProjectRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.BULK_BED_NIGHTS,
            element: (
              <ProjectEditRoute permissions={['canEditEnrollments']}>
                <BedNightsPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.BULK_ASSIGN_SERVICE,
            element: (
              <ProjectEditRoute permissions={['canEditEnrollments']}>
                <BulkServicesPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.BULK_BED_NIGHTS_NEW_HOUSEHOLD,
            element: (
              <ProjectEditRoute permissions={['canEnrollClients']}>
                <CreateHouseholdPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.BULK_SERVICE_NEW_HOUSEHOLD,
            element: (
              <ProjectEditRoute permissions={['canEnrollClients']}>
                <CreateHouseholdPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.REFERRALS,
            element: (
              <ProjectEditRoute
                permissions={[
                  'canManageIncomingReferrals',
                  'canManageOutgoingReferrals',
                ]}
                redirectRoute={Routes.PROJECT}
              >
                <ProjectReferralsPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.REFERRAL_POSTING,
            element: (
              <ProjectEditRoute
                permissions={['canManageIncomingReferrals']}
                redirectRoute={Routes.PROJECT}
              >
                <ProjectReferralPostingPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.ESG_FUNDING_REPORT,
            element: (
              <ProjectEditRoute
                permissions={['canManageIncomingReferrals']}
                redirectRoute={Routes.PROJECT}
              >
                <ProjectEsgFundingReport />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.NEW_REFERRAL_REQUEST,
            element: (
              <ProjectEditRoute
                permissions={['canManageIncomingReferrals']}
                redirectRoute={Routes.PROJECT}
              >
                <NewReferralRequestPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.NEW_OUTGOING_REFERRAL,
            element: (
              <ProjectEditRoute
                permissions={['canManageOutgoingReferrals']}
                redirectRoute={Routes.PROJECT}
              >
                <NewOutgoingReferralPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.EDIT_PROJECT,
            element: (
              <ProjectEditRoute>
                <EditProjectPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.INVENTORY,
            element: <InventoriesPage />,
          },
          {
            path: ProjectDashboardRoutes.HMIS_PARTICIPATION,
            element: <HmisParticipationsPage />,
          },
          {
            path: ProjectDashboardRoutes.CE_PARTICIPATION,
            element: <CeParticipationsPage />,
          },
          {
            path: ProjectDashboardRoutes.UNITS,
            element: <Units />,
          },
          {
            path: ProjectDashboardRoutes.NEW_INVENTORY,
            element: (
              <ProjectEditRoute>
                <EditInventoryPage create />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.EDIT_INVENTORY,
            element: (
              <ProjectEditRoute>
                <EditInventoryPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.FUNDERS,
            element: <FundersPage />,
          },
          {
            path: ProjectDashboardRoutes.NEW_FUNDER,
            element: (
              <ProjectEditRoute>
                <EditFunderPage create />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.EDIT_FUNDER,
            element: (
              <ProjectEditRoute>
                <EditFunderPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.COCS,
            element: <ProjectCocsPage />,
          },
          {
            path: ProjectDashboardRoutes.NEW_COC,
            element: (
              <ProjectEditRoute>
                <EditProjectCocPage create />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.EDIT_COC,
            element: (
              <ProjectEditRoute>
                <EditProjectCocPage />
              </ProjectEditRoute>
            ),
          },
          {
            path: ProjectDashboardRoutes.EXTERNAL_FORM_SUBMISSIONS,
            element: (
              <ProjectEditRoute
                permissions={['canManageExternalFormSubmissions']}
              >
                <ProjectExternalFormSubmissions />
              </ProjectEditRoute>
            ),
          },
          // Disabled for now because it's not ready for MVP.
          // {
          //   path: ProjectDashboardRoutes.ADD_SERVICES,
          //   element: (
          //     <ProjectEditRoute
          //       permissions={['canEditEnrollments']}
          //       redirectRoute={ProjectDashboardRoutes.PROJECT_ENROLLMENTS}
          //     >
          //       <AddServices />
          //     </ProjectEditRoute>
          //   ),
          // },
          {
            path: ProjectDashboardRoutes.ADD_HOUSEHOLD,
            element: (
              <ProjectEditRoute
                permissions={['canEnrollClients']}
                redirectRoute={ProjectDashboardRoutes.PROJECT_ENROLLMENTS}
              >
                <CreateHouseholdPage />
              </ProjectEditRoute>
            ),
          },
        ],
      },
      {
        path: Routes.CREATE_PROJECT,
        element: (
          <RootPermissionsFilter permissions={['canEditProjectDetails']}>
            <CreateProjectPage />
          </RootPermissionsFilter>
        ),
      },
      { path: Routes.ORGANIZATION, element: <OrganizationPage /> },
      {
        path: Routes.EDIT_ORGANIZATION,
        element: <EditOrganizationPage />,
      },
      {
        path: Routes.CREATE_ORGANIZATION,
        element: (
          <RootPermissionsFilter permissions='canEditOrganization'>
            <CreateOrganizationPage />
          </RootPermissionsFilter>
        ),
      },
      {
        path: Routes.CREATE_CLIENT,
        element: (
          <RootPermissionsFilter permissions='canEditClients'>
            <CreateClientPage />
          </RootPermissionsFilter>
        ),
      },
      {
        path: Routes.ENROLLMENT_DASHBOARD,
        // No need for a permission filter on th Enrollment Dashboard.
        // If the enrollment is not found, the EnrollmentDashboard component will render NotFound.
        element: <EnrollmentDashboard />,
        children: [
          { path: '', element: <Navigate to='overview' replace /> },
          {
            path: EnrollmentDashboardRoutes.ENROLLMENT_OVERVIEW,
            // No perm needed because it only requires enrollment visibility
            element: <EnrollmentOverview />,
          },
          {
            path: EnrollmentDashboardRoutes.HOUSEHOLD,
            // No perm needed because it only requires enrollment visibility
            element: <HouseholdPage />,
          },
          {
            path: EnrollmentDashboardRoutes.EDIT_HOUSEHOLD,
            element: (
              <EnrollmentRoute
                permissions='canEditEnrollments'
                redirectRoute={EnrollmentDashboardRoutes.ENROLLMENT_OVERVIEW}
              >
                <EditHouseholdPage />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.ASSESSMENTS,
            // No perm needed because it only requires enrollment visibility
            element: <EnrollmentAssessmentsPage />,
          },
          {
            path: EnrollmentDashboardRoutes.INTAKE,
            element: <IntakeAssessmentPage />,
          },
          {
            path: EnrollmentDashboardRoutes.EXIT,
            element: <ExitAssessmentPage />,
          },
          {
            // view/edit existing individual assessment
            path: EnrollmentDashboardRoutes.VIEW_ASSESSMENT,
            element: <IndividualAssessmentPage />,
          },
          {
            // create new individual assessment
            path: EnrollmentDashboardRoutes.NEW_ASSESSMENT,
            element: (
              <EnrollmentRoute
                permissions='canEditEnrollments'
                redirectRoute={EnrollmentDashboardRoutes.ENROLLMENT_OVERVIEW}
              >
                <NewIndividualAssessmentPage />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.SERVICES,
            // No perm needed because it only requires enrollment visibility
            element: <EnrollmentServicesPage />,
          },
          {
            path: EnrollmentDashboardRoutes.CURRENT_LIVING_SITUATIONS,
            element: (
              <EnrollmentRoute // Only requires enrollment visibility
                dataCollectionFeature={
                  DataCollectionFeatureRole.CurrentLivingSituation
                }
              >
                <EnrollmentCurrentLivingSituationsPage />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.EVENTS,
            element: (
              <EnrollmentRoute // Only requires enrollment visibility
                dataCollectionFeature={DataCollectionFeatureRole.CeEvent}
              >
                <EnrollmentCeEventsPage />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.AUDIT_HISTORY,
            element: (
              <EnrollmentRoute
                permissions='canAuditEnrollments'
                redirectRoute={EnrollmentDashboardRoutes.ENROLLMENT_OVERVIEW}
              >
                <EnrollmentAuditHistory />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.CE_ASSESSMENTS,
            element: (
              <EnrollmentRoute // Only requires enrollment visibility
                dataCollectionFeature={DataCollectionFeatureRole.CeAssessment}
              >
                <EnrollmentCeAssessmentsPage />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.CUSTOM_CASE_NOTES,
            element: (
              <EnrollmentRoute // Only requires enrollment visibility
                dataCollectionFeature={DataCollectionFeatureRole.CaseNote}
              >
                <EnrollmentCaseNotes />
              </EnrollmentRoute>
            ),
          },
          {
            path: EnrollmentDashboardRoutes.ESG_FUNDING_REPORT,
            element: (
              <EnrollmentProjectRoute
                permissions='canManageIncomingReferrals'
                redirectRoute={EnrollmentDashboardRoutes.ENROLLMENT_OVERVIEW}
              >
                <EnrollmentEsgFundingReport />
              </EnrollmentProjectRoute>
            ),
          },
          { path: '*', element: <Navigate to='overview' replace /> },
        ],
      },
      {
        path: Routes.CLIENT_DASHBOARD,
        // No need for a permission filter on th Client Dashboard.
        // If the client is not found, the ClientDashboard component will render NotFound.
        element: <ClientDashboard />,
        children: [
          { path: '', element: <Navigate to='profile' replace /> },
          {
            path: ClientDashboardRoutes.PROFILE,
            element: <ClientProfilePage />,
          },
          {
            path: ClientDashboardRoutes.EDIT,
            element: (
              // Prevent UI-access to edit client if user lacks name access, to avoid situation where masked name ("Client X") is submitted. See follow-up #187358025
              // This is just a safeguard against a situation that should never happen, so we aren't adding checks to hide the button/link to this route everywhere it appears
              <ClientRoute
                permissions={['canEditClient', 'canViewClientName']}
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <EditClientPage />
              </ClientRoute>
            ),
          },
          // disabled for now #185750557
          // {
          //   path: ClientDashboardRoutes.NEW_ENROLLMENT,
          //   element: (
          //     <ClientRoute
          //       permissions='canEditEnrollments'
          //       redirectRoute={ClientDashboardRoutes.CLIENT_ENROLLMENTS}
          //     >
          //       <NewEnrollment />
          //     </ClientRoute>
          //   ),
          // },
          {
            path: ClientDashboardRoutes.CLIENT_ENROLLMENTS,
            element: (
              <ClientRoute
                permissions='canViewEnrollmentDetails'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientEnrollmentsPage />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.CASE_NOTES,
            element: (
              <ClientRoute
                permissions='canViewEnrollmentDetails'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientCaseNotes />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.SCAN_CARDS,
            element: (
              <ClientRoute
                permissions='canManageScanCards'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientScanCards />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.ASSESSMENTS,
            element: (
              <ClientRoute
                permissions='canViewEnrollmentDetails'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientAssessmentsPage />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.SERVICES,
            element: (
              <ClientRoute
                permissions='canViewEnrollmentDetails'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientServicesPage />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.AUDIT_HISTORY,
            element: (
              <ClientRoute
                permissions='canAuditClients'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientAuditHistory />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.MERGE_HISTORY,
            element: (
              <RootPermissionsFilter permissions='canMergeClients'>
                <ClientMergeHistory />
              </RootPermissionsFilter>
            ),
          },
          {
            path: ClientDashboardRoutes.NEW_MERGE,
            element: (
              <RootPermissionsFilter permissions='canMergeClients'>
                <NewClientMerge />
              </RootPermissionsFilter>
            ),
          },
          {
            path: ClientDashboardRoutes.FILES,
            element: (
              <ClientRoute
                permissions='canViewAnyFiles'
                redirectRoute={ClientDashboardRoutes.PROFILE}
              >
                <ClientFilesPage />
              </ClientRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.NEW_FILE,
            element: (
              <FileEditRoute create>
                <EditFilePage create />
              </FileEditRoute>
            ),
          },
          {
            path: ClientDashboardRoutes.EDIT_FILE,
            element: (
              <FileEditRoute>
                <EditFilePage />
              </FileEditRoute>
            ),
          },
          { path: '*', element: <Navigate to='profile' replace /> },
        ],
      },
      {
        path: Routes.ADMIN,
        element: <AdminDashboard />,
        children: [
          {
            path: '',
            element: <AdminLandingPage />,
          },
          {
            path: AdminDashboardRoutes.CLIENT_MERGE_HISTORY,
            element: (
              <RootPermissionsFilter permissions='canMergeClients'>
                <GlobalClientMergeHistory />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.PERFORM_CLIENT_MERGES,
            element: (
              <RootPermissionsFilter permissions='canMergeClients'>
                <AdminClientMerge />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.AC_DENIALS,
            element: (
              <RootPermissionsFilter permissions='canManageDeniedReferrals'>
                <AdminReferralDenialsPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.AC_DENIAL_DETAILS,
            element: (
              <RootPermissionsFilter permissions='canManageDeniedReferrals'>
                <AdminReferralPosting />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.USERS,
            element: (
              <RootPermissionsFilter
                permissions={['canImpersonateUsers', 'canAuditUsers']}
                mode='any'
              >
                <AdminUsers />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.USER_CLIENT_ACCESS_HISTORY,
            element: (
              <RootPermissionsFilter permissions='canAuditUsers'>
                <UserAuditPage
                  userHistoryType='access'
                  accessEntityType='clients'
                />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.USER_ENROLLMENT_ACCESS_HISTORY,
            element: (
              <RootPermissionsFilter permissions='canAuditUsers'>
                <UserAuditPage
                  userHistoryType='access'
                  accessEntityType='enrollments'
                />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.USER_EDIT_HISTORY,
            element: (
              <RootPermissionsFilter permissions='canAuditUsers'>
                <UserAuditPage userHistoryType='edits' />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.FORMS,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <FormDefinitionsPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.VIEW_FORM,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <FormDefinitionDetailPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.EDIT_FORM,
            element: (
              <RootPermissionsFilter permissions='canManageForms'>
                <FormBuilderPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.JSON_EDIT_FORM,
            element: (
              <RootPermissionsFilter permissions='canAdministrateConfig'>
                <JsonFormEditorPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.PREVIEW_FORM,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <FormPreview />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.PREVIEW_FORM_DRAFT,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <FormPreview />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.CONFIGURE_SERVICES,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <ServiceTypePage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.CONFIGURE_SERVICE_TYPE,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <ServiceTypeDetailPage />
              </RootPermissionsFilter>
            ),
          },
          {
            path: AdminDashboardRoutes.PROJECT_CONFIG,
            element: (
              <RootPermissionsFilter permissions='canConfigureDataCollection'>
                <ProjectConfigPage />
              </RootPermissionsFilter>
            ),
          },
        ],
      },
      { path: '/', element: <ClientSearchPage /> },
      {
        path: '*',
        element: (
          <PathHandler>
            <NotFound />
          </PathHandler>
        ),
      },
    ],
  },
  {
    path: '/system_status/:detailType',
    element: <SystemStatus />,
  },
];
